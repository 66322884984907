import App from 'next/app';
import Head from 'next/head';

import React from 'react';

import { ConfigProvider } from 'antd';

import '$DATA/Less/merge.less';



export default class MyApp extends App {
	render(){
		const { Component, pageProps } = this.props;	
		console.log('====================================');
		console.log(this.props);
		console.log('====================================');
		return (
			<>
				<Head>
					<link rel="icon" type="image/png" sizes="32x32" href="/static/images/favicon.ico"></link>
					<title>{pageProps.title || '乐天堂FUN88快速注册,亚洲顶级真人体育游戏平台,真人娱乐场/老虎机/彩票应有尽有'}</title>
				</Head>
				<ConfigProvider>
					<Component { ...pageProps } />
				</ConfigProvider>
			</>
		)
	}
}